import img from '/public/worldmap.webp'
import logo from '/public/logo2.webp'
import BlurImage from '@/components/BlurImage'

export const SecondPage = () => {
    return (
        <section className={'flex flex-col max-w-screen noise bg-primary relative'}>
            <h1
                className={'text-white mx-auto max-w-3xl p-4 text-xl md:text-3xl font-semibold mt-3 text-center'}
                data-aos="fade-up"
            >
                Temos consultores de viagens especializados em todos os destinos do mundo
            </h1>
            <BlurImage
                src={img}
                alt={'Mapa'}
                className={'mx-auto max-w-2xl w-full md:max-h-screen px-4 py-12 z-10'}
                data-aos="fade-down"
            />
            <BlurImage src={logo} alt={'Mapa'} className={'absolute bottom-0'} data-aos="fade-up" />
        </section>
    )
}
