import React, { useState } from 'react'
import Image from 'next/image'
import styles from './Header.module.css'
import Logo from '/public/logo.webp'
import Link from 'next/link'

export const Header = () => {
    const [activeSection, setActiveSection] = useState('home')

    return (
        <header className={`${styles.glass} backdrop-blur-lg max-w-screen flex mb-11 mx-auto`}>
            <nav className="flex items-center md:justify-between justify-center max-w-5xl mx-auto w-full">
                <Image src={Logo} alt={'Fly Prime'} className={'max-w-32'} />

                <ul className="md:flex hidden gap-3 menu menu-horizontal text-white font-light text-xs">
                    <li>
                        <Link
                            href="#home"
                            onClick={() => setActiveSection('home')}
                            className={activeSection === 'home' ? 'active' : ''}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="#emitir-passagens"
                            onClick={() => setActiveSection('emitir-passagens')}
                            className={activeSection === 'emitir-passagens' ? 'active' : ''}
                        >
                            Emitir Passagens
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="#depoimentos"
                            onClick={() => setActiveSection('depoimentos')}
                            className={activeSection === 'depoimentos' ? 'active' : ''}
                        >
                            Depoimentos
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="#contato"
                            onClick={() => setActiveSection('contato')}
                            className={activeSection === 'contato' ? 'active' : ''}
                        >
                            Contato
                        </Link>
                    </li>
                    <li>
                        <Link
                            href="#ajuda"
                            onClick={() => setActiveSection('ajuda')}
                            className={activeSection === 'ajuda' ? 'active' : ''}
                        >
                            Ajuda
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
